<template>
  <div>
    <div class="AllCourse">
      <div class="container">
        <div class="AllCourse__top row mt-3 mb-3 align-items-center mx-auto">
          <form class="d-flex position-relative AllCourse__searchForm col-12 col-md-3 ps-0" 
            @submit.prevent="page = 1;isUpdated = 0;getData()"
            data-test="searchForm">
            <button class="btn AllCourse__searchBtn position-absolute" type="submit"><i class="fas fa-search"></i></button>
            <input class="form-control me-2 AllCourse__search" type="search" placeholder="Search" aria-label="Search" v-model="query" @change="page = 1;isUpdated = 0;getData()">
          </form>
          <!-- <div class="AllCourse__top-badges d-flex align-items-center ms-auto justify-content-center col-12 col-md-6">
            <div class="AllCourse__badge" v-for="item in categories" :key="item.id"
              @click.prevent="CourseCategoryId = item.id;getData()">
              {{ item.name }}
            </div>

          </div> -->
          <div class="col-12 col-md-9 d-flex mt-3 mt-md-0 justify-content-end">
            <select class="form-select courses__filter me-2" v-model="CourseCategoryId" @change="page = 2;isUpdated = 0;getData()">
              <option disabled value='0'>課程類型</option>
              <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
            </select>
            <select class="form-select courses__filter courses__filter-test ms-2" aria-label="courses__filter"  v-model="OrderBy" @change="page = 2;isUpdated = 0;getData()">
              <option value="late" disabled>排序依據</option>
              <option value="latest">最新發布</option>
              <option value="nop">最多人數</option>
              <option value="rating">最高評價</option>
            </select>
            <!-- <select class="form-select courses__filter courses__filter-test ms-2" aria-label="Default select example" @change="changeOrderBy">
              <option selected disabled value="latest">排序依據</option>
              <option value="latest">最新發布</option>
              <option value="nop">最多人數</option>
              <option value="rating">最高評價</option>
            </select> -->
          </div>

        </div>
        <div class="d-flex justify-content-end mb-4">
          <!-- <div>
            <span class="me-1">以標籤搜尋</span>
            <router-link to="/courses_tag">GO &#8594</router-link>
          </div> -->
          <i class="fas fa-sort-amount-down d-inline-block text-end me-2"
           style="font-size: 1.5rem;"
           v-if="Ordering == 'DESC'"
           @click.prevent="toggleOrdering('ASC')"></i>
          <i class="fas fa-sort-amount-up d-inline-block text-end me-2"
            style="font-size: 1.5rem;"
            v-if="Ordering == 'ASC'"
            @click.prevent="toggleOrdering('DESC')"></i>
        </div>
      </div>
      <div class="container">
        <div class="row" v-if="!noData">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" v-for="(item, i) in data" :key="`${i}${item.id}`">
            <CourseCard :course="item">
              <template slot="purchased" v-if="item.purchased">
                <span class="btn btn-purchased" @click.stop="$router.push(`/course_main/${item.id}`)">
                  <p>上課去</p>
                </span>
              </template>
            </CourseCard>
          </div>
        </div>
        <div v-if="noData">
          <p class="noData__txt">找不到您搜尋的課程</p>
          <img src="../assets/images/empty.svg" alt="" width="250">
        </div>
      </div>
    </div>
    <Meta :title ="title" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import CourseCard from '../components/CourseCard';
import Meta from '../components/Meta';

export default {
  name: 'AllCourse',
  components: {
    CourseCard,
    Meta
  },
  data() {
    return {
      filter: 'latest',
      query: '',
      data: [],
      page: 2,
      CourseName: '',
      CourseCategoryId: 0,
      OrderBy: 'late',
      Ordering: 'DESC ',
      isUpdated: 0,
      routerQuery: '',
      noData: false,
      categories: [],
      title: '課程 - 元學堂',
    };
  },
  metaInfo: {
    title: "全部課程 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '全部課程 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    toggleOrdering(order) {
      this.Ordering = order;
      this.data = [];
      this.page = 2;
      this.isUpdated = 0;
      this.getData();
    },
    getCategories() {
      this.$http.get(`${this.$API_PATH}/Course/Categories`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.categories = res.data.data.courseCategories;
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getData() {
      const query = {
        CourseName: this.query ? this.query : '',
        CourseCategoryId: this.CourseCategoryId,
        OrderBy: this.OrderBy,
        Ordering: this.Ordering,
        page: 1,
      };
      this.$log.debug(this.OrderBy);
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data);
        this.data = res.data.data.courseList;
        this.updateLoading(false);
        if (this.data.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      }).catch((err) => {
        this.updateLoading(false);
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    scrollLoad() {
      const vm = this;
      const query = {
        CourseName: vm.query ? vm.query : '',
        CourseCategoryId: vm.CourseCategoryId,
        OrderBy: vm.OrderBy,
        Ordering: vm.Ordering,
        page: vm.page,
      };

      window.addEventListener('scroll', function () {
        if (window.scrollY + window.innerHeight >= (document.body.offsetHeight - 100) && vm.isUpdated == 0) {
          vm.isUpdated = 1;
          if (vm.isUpdated == 1) {
            query.CourseName = vm.query;
            query.page = vm.page;
            query.CourseCategoryId = vm.CourseCategoryId;
            query.OrderBy = vm.OrderBy;
            query.Ordering = vm.Ordering;
            vm.$http.post(`https://api1.metalearning.com.tw/Course/All`, query, {
              headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                Authorization: `bearer ${this.userAuth}`,
              },
            }).then((res) => {
              if (res.data.data.courseList.length > 0) {
                vm.isUpdated = 0;
                vm.page++;
                vm.data.push(...res.data.data.courseList);
              } else {
                vm.isUpdated = 1;
              }
            }).catch((err) => {
              this.$router.push('/error');
              this.$log.debug(err.response);
            });
          }
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  mounted() {
    this.scrollLoad();
  },
  destroyed() {
    window.removeEventListener('scroll', function () {
      if (window.scrollY + window.innerHeight >= (document.body.offsetHeight - 100) && vm.isUpdated == 0) {
        vm.isUpdated = 1;

        if (vm.isUpdated == 1) {
          query.CourseName = vm.query;

          query.page = vm.page;
          query.CourseCategoryId = vm.CourseCategoryId;
          query.OrderBy = vm.OrderBy;
          query.Ordering = vm.Ordering;
          vm.$http.post(`https://api1.metalearning.com.tw/Course/All`, query, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: `bearer ${this.userAuth}`,
            },
          }).then((res) => {
            vm.$log.debug(res.data);

            if (res.data.data.courseList.length > 0) {
              vm.isUpdated = 0;
              vm.page++;
              vm.data.push(...res.data.data.courseList);
            } else {
              vm.isUpdated = 1;
            }
          }).catch((err) => {
            this.$router.push('/error');
            vm.$log.debug(err.response);
          });
        }
      }
    });
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.getCategories();
    const vm = this;
    // this.$bus.$on('footer: category', (cid) => {
    //   vm.CourseCategoryId = cid;
    //   vm.page = 2;
    //   vm.isUpdated = 0;
    //   vm.getData();
    // });
    if (this.$route.query || this.$route.params.category) {
      if (this.$route.query.keyword) {
        this.routerQuery = this.$route.query.keyword;
        this.query = this.routerQuery;
      } else if (this.$route.params.category) {
        this.CourseCategoryId = this.$route.params.category;
      }
      const query = {
        CourseName: this.query,
        CourseCategoryId: this.CourseCategoryId,
        OrderBy: this.OrderBy,
        Ordering: this.Ordering,
        page: 1,
      };
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        vm.$log.debug(res.data.data);
        this.data = res.data.data.courseList;
        vm.page = 2;
        this.updateLoading(false);
      }).catch((err) => {
        this.updateLoading(false);
        // this.$router.push('/error');
        vm.$log.debug(err.response);
      });
    } else {
      this.getData();
    }
  },
};
</script>
